import Vue from 'vue'
import { ResponseWrapper, ErrorWrapper } from './util'

export class BaseService {
  static get entity () {
    throw new Error('entity getter not defined')
  }
  static get name () {
    throw new Error('name getter not defined')
  }
  static get profile () {
    const profile = JSON.parse(localStorage.getItem('user')).role.name;
    return profile;
  }

  /**
   * The method used to perform an AJAX-request.
   *
   * @param {string}      requestType The request type.
   * @param {string}      url         The URL for the request.
   * @param {string|null} name        The name of the object response.
   * @param {Object|null} data        The data to be send with the request.
   * @param {Object|null} config      The config object to be send with the request.
   *
   * @returns {Promise} The result in a promise.
   */
  static submit(requestType, url, name = null, data = null, config = null) {
    return new Promise((resolve, reject) => {
      Vue.$http[requestType](url, data, config)
        .then((response) => {
          let data = name ? response.data[name] : response.data;
          if (config == null) {
            resolve(data);
          } else {
            if (config.showResponseWrapper) {
              resolve(new ResponseWrapper(response, data));
            } else {
              resolve(data);
            }
          }
        })
        .catch(({ response }) => {
          if (response) {
            console.log(response);
            reject(new ErrorWrapper(response));
            // reject(response);
            // throw new ErrorWrapper(response)
          } else {
            reject();
          }
        });
    });
  }

  /**
   * ------------------------------
   * @HELPERS
   * ------------------------------
   */

  static responseWrapper (...rest) {
    return new ResponseWrapper(...rest)
  }

  static errorWrapper (...rest) {
    return new ErrorWrapper(...rest)
  }

  /**
   * ----------------------------
   * BASIC API CALLS
   * ----------------------------
   */

  static async getAll () {
    return this.submit('get', `${this.profile}/${this.entity}`, this.name);
  }

  static async getById (id) {
    return this.submit('get', `${this.profile}/${this.entity}/${id}`, this.name);
  }

  static async create (data = {}, config = {}) {
    return this.submit('post', `${this.profile}/${this.entity}`, this.name, data, config);
  }

  static async update (id, data = {}) {
    console.log(id);
    return this.submit('put', `${this.profile}/${this.entity}/${id}`, this.name, data);
  }

  static async remove (id) {
    return this.submit('delete', `${this.profile}/${this.entity}/${id}`, this.name);
  }

  static async getTrash () {
    return this.submit('get', `${this.profile}/${this.entity}/trash`, this.name);
  }

  static async restore (id) {
    return this.submit('delete', `${this.profile}/${this.entity}/activate/${id}`, this.name);
  }

}
