import {
    BaseService
} from './../base'
export class IndicatorService extends BaseService {
    static get entity() {
        return 'indicator'
    }
    static get name() {
        return 'indicator'
    }
    static async findClassificationId(id) {
        return this.submit("get", `${this.profile}/${this.entity}/find_by_classification_indicator_id/${id}`, this.name);
    }
    static async findSubIndicator(id) {
        return this.submit("get", `${this.profile}/${this.entity}/find_by_sub_indicator/${id}`, this.name);
    }
    static async getBySubIndicatorNull() {
        return this.submit("get", `${this.profile}/${this.entity}/find_by_sub_indicator_null`, this.name);
    }
}