<template>
<section>
    <div style="margin:2%">
        <h3>
          Medio de Verificación
        </h3>

        {{detail.half_verification }}
          <br><br>
          <h3>
            Formula
          </h3>
          {{detail.formula}}
          <br><br>
          <h3>
            Meta
          </h3>
          {{detail.goal}}
          <br>
          <table-sub-indicators :indicator="detail" :isAdministrative="isAdministrative"/>
    </div>
</section>
</template>
<script>
import TableSubIndicators from './TableSubIndicators.vue'
export default {
  components: { TableSubIndicators },
    props:['detail','isAdministrative']
}
</script>