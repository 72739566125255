<template>
  <PlantillaHome>
    <div slot="contenido">
        <report-indicators></report-indicators>
    </div>
  </PlantillaHome>
</template>
<script>
import ReportIndicators from '../../components/reports/ReportIndicators';
import PlantillaHome from "../../templates/PlantillaHome";
export default {
  components: {
    PlantillaHome,
    ReportIndicators,
  },
  data() {
    return {
    };
  },
};
</script>