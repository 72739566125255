<template>
    <v-dialog
      v-model="dialog"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Subiendo {{actuallyNumber}} de {{total}}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>
<script>
import { eventBus } from '../../main'
export default {
    data(){
        return{
            total:null,
            actuallyNumber:null,
            dialog:false,
        }
    },
    mounted(){
        eventBus.$on('showCountdown',(actual,total)=>{
            this.dialog=true;
            this.actuallyNumber=actual+1;
            this.total=total;
        });
        eventBus.$on('hideCountdown',()=>{
            this.dialog=false;
        })
    }
}
</script>