import Vue from 'vue'
import Axios from 'axios';
import { eventBus } from '../main';

Axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}/api`;
Axios.defaults.headers.common.Accept = 'application/json';

// const axios = require('axios').create({
//   baseURL: process.env.VUE_APP_API_URL
// });

Axios.interceptors.response.use(
  response => response,
  (error) => {
    if (error.response.status === 401) {
      // store.dispatch('auth/logout');
      eventBus.$emit("value", true);
    }

    return Promise.reject(error);
  },
);

Axios.interceptors.request.use((request, config) => {
  console.log(config);
  const token = `Bearer ${window.localStorage.getItem("token")}`;
  if (token) {
    request.headers.Authorization = token;
  }
  return request;
}, error => {
  return Promise.reject(error)
});

// Bind Axios to Vue.
Vue.$http = Axios;
Object.defineProperty(Vue.prototype, '$http', {
  get() {
    return Axios;
  },
});
