<template>
  <PlantillaHome>
    <div slot="contenido">
      <v-card  shaped>
        <v-card-title>
          SERVICIO DE SALUD MAGALLANES
        </v-card-title>
        <v-card-subtitle> MINISTERIO DE SALUD </v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col cols="2" sm="1">
              <v-avatar size="83" rounded="0">
                <v-img
                  contain
                  max-height="97"
                  max-width="105"
                  src="/logo.png"
                ></v-img>
              </v-avatar>
            </v-col>
            <v-col cols="8">
              <v-list-item >
                <v-list-item-content>
                  <v-list-item-title class="title">
                    {{userData.name}} {{userData.pather_lastname}} {{userData.mother_lastname}}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{userData.run}}</v-list-item-subtitle>
                  <v-list-item-subtitle>{{userData.email}}</v-list-item-subtitle>                  
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn rounded color="primary" right fab absolute>
            <v-icon>mdi-key-change</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </PlantillaHome>
</template>
<script>
import PlantillaHome from "../../templates/PlantillaHome";

export default {
  components: {
    PlantillaHome,
  },
  data() {
    return{
      userData:null      
    }
  },
  created() {},
  mounted() {
    this.userData=JSON.parse(localStorage.getItem('user'))
  },
  methods: {},
};
</script>