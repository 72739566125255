<template>
  <v-card>
    <v-card-text>
      <v-select
        outlined
        dense
        :items="listTypeIndicators"
        v-model="typeIndicatorId"
        item-text="name"
        item-value="id"
        label="Tipo de indicador"
        :loading="loading"
        @change="loadClassificationIndicator"
      >
      </v-select>
      <v-select
        outlined
        dense
        :items="listClassificationIndicators"
        v-model="classificationIndicatorId"
        item-text="titleDescription"
        item-value="id"
        label="Objetivo"
        :loading="loading"
        @change="loadIndicators"
      >
      </v-select>
      <v-select
        outlined
        dense
        :items="listIndicators"
        v-model="indicatorId"
        item-text="name"
        item-value="id"
        :loading="loading"
        label="Indicador"
      >
      </v-select>
      <v-file-input
        v-model="file"
        show-size
        label="Resolución adjunta"
      ></v-file-input>
      <v-btn :loading="loading" @click="addIndicator" elevation="2" color="teal" dark>
        <v-icon left dark> mdi-plus </v-icon>
        Agregar
      </v-btn>
      <v-data-table
        :headers="[
          {
            text: 'Indicador',
            value: 'indicator.name',
          },
          {
            text: 'Acciones',
            value: 'actions',
            width: '120px',
          },
        ]"
        :items="userIndicators"
        item-key="rut"
        class="elevation-1"
        no-data-text="No hay indicadores ingresados"
        loading-text="Cargando... Favor de esperar"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-row align="center">
            <v-btn
              small
              color="teal"
              class="white--text"
              fab
              :disabled="!item.file"
              style="margin-right: 1em"
              @click="openPreview(item.file)"
            >
              <v-icon small>mdi-eye </v-icon>
            </v-btn>
            <v-btn :loading="loadingDelete && deleteSelected == item.id" small color="error" fab @click="deleteIndicator(item.id, item.indicator_id)">
              <v-icon small>mdi-delete </v-icon>
            </v-btn>
          </v-row>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { IndicatorService } from "../../../services/models/indicator";
import { ClassificationIndicatorService } from "../../../services/models/classificationIndicator";
import { TypeIndicatorService } from "../../../services/models/typeIndicator";
import { IndicatorUserService } from '../../../services/models/indicatorUser';

export default {
  props: ['indicatorsUsers', 'userId'],
  data() {
    return {
      listTypeIndicators: [],
      listClassificationIndicators: [],
      listIndicators: [],
      typeIndicatorId: null,
      indicatorId: null,
      classificationIndicatorId: null,
      userIndicators: this.indicatorsUsers ? this.indicatorsUsers : [],
      file: null,
      loading: false,
      loadingDelete: false,
      deleteSelected: null
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      Promise.all([
        TypeIndicatorService.getAll(),
        ClassificationIndicatorService.getAll(),
        IndicatorService.getAll(),
      ])
        .then((response) => {
          this.listTypeIndicators = response[0];
          this.listClassificationIndicators = response[1].map((value) => ({
            ...value,
            titleDescription: `${value.name} - ${value.description}`,
          }));
          this.listIndicators = response[2];
        })
        .catch((err) => {
          console.log(err);
          this.showAlert({
            color: "error",
            text:
              "Ha ocurrido un error al cargar los datos, favor intentar más tarde",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async loadClassificationIndicator() {
      this.listClassificationIndicators = (
        await ClassificationIndicatorService.findTypeId(this.typeIndicatorId)
      ).map((value) => ({
        ...value,
        titleDescription: `${value.name} - ${value.description}`,
      }));
    },
    async loadIndicators() {
      this.listIndicators = await IndicatorService.findClassificationId(
        this.classificationIndicatorId
      );
    },
    openPreview(file) {
      window.open(file,'_blank');
      // let pdfWindow = window.open("");
      // pdfWindow.document.write(
      //   "<iframe width='100%' height='100%' src='" + filePreview + "'></iframe>"
      // );
    },
    async addIndicator() {
      if (!this.indicatorId) {
        this.showAlert({
          color: "warning",
          text: "Por favor seleccionar indicador",
        });
        return;
      }
      if (
        this.userIndicators.find(
          (indicator) => indicator.indicator_id == this.indicatorId
        )
      ) {
        this.showAlert({
          color: "warning",
          text: "El indicador ya se encuentra asociado al usuario",
        });
        return;
      }
      let indicator = this.listIndicators.find(
        (indicator) => indicator.id == this.indicatorId
      );
      let filePreview;
      this.loading = true;
      try {
        if (this.file) {
          let formData = new FormData();
          formData.append("indicator_id", indicator.id);
          formData.append("user_id", this.userId);
          formData.append("file", this.file);
          let indicatorUser = await IndicatorUserService.create(formData, {
            "Content-Type": "multipart/form-data",
          });
          let reader = new FileReader();
          reader.readAsDataURL(this.file);
          reader.onload = () => {
            filePreview = reader.result;
            this.userIndicators.push({
              ...indicator,
              filePreview,
              id: indicatorUser,
              file: this.file,
              indicator: {
                name: indicator.name
              }
            });
          };
        } else {
          let indicatorUser = await IndicatorUserService.create({
            indicator_id: indicator.id,
            user_id: this.userId
          });
          this.userIndicators.push({
            ...indicator,
            id: indicatorUser.id,
            indicator: {
              name: indicator.name
            }
          });
        }
        this.showAlert({
          color: "success",
          text: "El indicador se ha asociado correctamente al usuario",
        });
      } catch (error) {
        console.log(error);
        this.showAlert({
          color: "error",
          text: "Ha ocurido un error al agregar el perfil",
        });
      } finally {
        this.loading = false;
      }
    },
    async deleteIndicator(id) {
      this.loadingDelete = true;
      this.deleteSelected = id;
      try {
        await IndicatorUserService.remove(id);
        this.userIndicators = this.userIndicators.filter(
          (indicator) => indicator.id != id
        );
        this.showAlert({
          color: "success",
          text: "El perfil se ha eliminado correctamente",
        });
      } catch (error) {
        console.log(error);
        this.showAlert({
          color: "error",
          text: "Ha ocurido un error al eliminar el perfil",
        });
      } finally {
        this.loadingDelete = false;
      }
    },
  },
};
</script>