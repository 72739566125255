import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';

import router from './router';

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts);

Vue.component('apexchart', VueApexCharts);

import './thirdParty';

export const eventBus = new Vue();

Vue.mixin({
  methods: {
    getCurrentUserLoggedIn() {
      return JSON.parse(window.localStorage.getItem("user"));
    },
    showAlert(options) {
      eventBus.$emit("showNotification", options);
    },
    showCountdown(number,total){
      eventBus.$emit('showCountdown',number,total)
    },
    hideCountdown(){
      eventBus.$emit('hideCountdown');
    }

  },
})

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
