<template>
  <v-data-table
    :headers="headers"
    :items="listSubIndicators"
    item-key="id"
    class="elevation-1"
    :loading="loading"
    loading-text="Cargando... Favor de esperar"
  no-data-text="Sin Sub-indicadores"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Sub indicadores</v-toolbar-title>
      </v-toolbar>
    </template>
    <template v-if="!isAdministrative" v-slot:item.create="{ item }">
      <create-register :indicator_id="item.id"></create-register>
    </template>
    <template v-slot:item.viewList="{ item }">
      <router-link
        :to="{
          name: `viewRegister`,
          params: { id: item.id },
        }"
      >
        <v-btn x-small fab color="primary" dark>
          <v-icon dark> mdi-eye </v-icon>
        </v-btn>
      </router-link>
    </template>
     <template v-slot:item.actions="{ item }">
        <v-row align="center" >          
          <delete :indicator="item" />
        </v-row>
      </template>
  </v-data-table>
</template>
<script>
import CreateRegister from "../../../components/management_control/register/Create";
import { eventBus } from '../../../main';
import { IndicatorService } from "../../../services/models/indicator";
import Delete from '../../manteiners/indicators/Delete.vue';

export default {
  props: ["indicator", "isAdministrative"],
  components: {
    CreateRegister,    
    Delete,
  },
  data() {
    return {
      loading: false,
      headers: [
        { text: "Nombre", value: "name", align: "start", sortable: true },
        { text: "Responsable", value: "user.name", sortable: true },
        { text: "Nuevo", value: "create", sortable: true },
        { text: "Detalle", value: "viewList", sortable: true },        
          { text: "Acciones", value: "actions", sortable: true },
      ],
      listSubIndicators:[]
    };
  },
  methods: {
    async loadData() {
      this.loading = true;
      this.listSubIndicators = await IndicatorService.findSubIndicator(
        this.indicator.id
      );
      this.loading = false;
    },
  },
  mounted() {
    this.loadData();
    eventBus.$on('loadSubIndicators',()=>{
      this.loadData();
    });
  },
  beforeDestroy(){
    eventBus.$off('loadSubIndicators');
  }
};
</script>