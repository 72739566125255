<template>
  <PlantillaHome>
    <div slot="contenido">
      <v-card elevation="10" class="d-flex pa-4">
        <!-- <v-btn elevation="10" color="primary" fab><v-icon dark> mdi-plus </v-icon></v-btn>         -->        
        <type-indicator-list/>
      </v-card>
    </div>
  </PlantillaHome>
</template>
<script>
import PlantillaHome from "../../../../templates/PlantillaHome";
import TypeIndicatorList from '../../../../components/manteiners/typesIndicators/TypeIndicatorList.vue';
export default {
  components: {
    PlantillaHome,    
    TypeIndicatorList,    
  },
  data() {
    return {
      
    };
  },
  methods: {},
};
</script>