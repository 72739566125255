<template>
  <v-dialog v-model="dialog" max-width="1000px" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        x-small
        fab
        color="secondary"
        dark
        :bottom="true"
        :right="true"
        v-bind="attrs"
        v-on="on"
        @click="mostrarModal"
      >
        <v-icon dark> mdi-plus </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Añadir Nota</v-card-title>
      <v-card-text style="height: 100%">
        <v-overlay :value="loading" absolute>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card-text>
            <v-form>
                
               
        <v-textarea
                label="Nota"
                :rules="fieldRules"
                required
                outlined
                dense
                v-model="notesData.note"
                prepend-inner-icon="mdi-bullseye-arrow"
        ></v-textarea>   

          
            </v-form>
          </v-card-text>
      </v-card-text>
      <v-card-actions flat>
        <v-btn @click="dialog = false" color="secondary">Cerrar</v-btn>
        <v-btn color="primary" @click="saveRegister()"
          ><v-icon left small> mdi-content-save </v-icon> Guardar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { eventBus } from "../../../main";
import { NoteService } from "../../../services/models/note";
export default {
  props: ["register_indicator_id"],
  data() {
    return {
      dialog: false,
      loading: false,
      fieldRules: [(v) => !!v || "Este campo es obligatorio"],
      notesData:{
        register_indicator_id:this.register_indicator_id,
        user_id:JSON.parse(window.localStorage.getItem("user")).id,
        note:null
      }
    };
  },
 
  methods: {
    async mostrarModal() {
      this.dialog = true;
      this.loading = true;
      this.loading = false;
    },


   async saveRegister(){
   
      try {
        this.loading = true;
        await NoteService.create(this.notesData);
          this.showAlert({
          color: "success",
          text: "Se ha credo la nota con exito"
        });
        eventBus.$emit("loadNotes")
        this.notesData.note=""
        this.loading = false;
      } catch (error) {
        console.log(error);
         this.showAlert({
          color: "error",
          text: "error al crear Registro, verificar datos"
        });
      

      } finally {
        this.loading = false;
        this.dialog = false;
      }
    },
  },
};
</script>