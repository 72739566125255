import EmptyRouterView from './../views/administrative/EmptyRouterView.vue';
import Administrative from './../views/administrative/Administrative.vue';
import Reports from './../views/administrative/ReportsView.vue';
import Register from './../views/administrative/register/Register.vue';

export const routes = [
  { path: '/administrativo',
    component: EmptyRouterView,
    children: [
      {
        path: 'inicio',
        name: 'administrativeHome', 
        component: Administrative,
      },  
      {
        path: 'reportes',
        name: 'reportsAdministrative', 
        component: Reports,
      },
      {
        path: 'registro',
        name: 'registerAdministrative',
        component: Register,
      },
    ]
  },
]