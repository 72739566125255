<template>
     <v-file-input        
        multiple
        chips
        show-size
        truncate-length="100"
        prepend-icon="mdi-file"
        required
        v-model="filesList"
        label="Adjuntar Archivos"
    ></v-file-input>
</template>
<script>
import { eventBus } from '../../main'
export default {
    data(){
        return{
            filesList: [],
        }
    },
    mounted(){
        eventBus.$on('clearFilesRegisterIndicatorList',()=>{
            this.filesList=[];
        })
    }
}
</script>