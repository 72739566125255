<template>
  <div>
    <v-overlay :value="loading" absolute>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card elevation="10" class="d-flex pa-4">
      <v-container>
        <v-row>
          <v-col cols="3">
            <v-select
              outlined
              dense
              :items="listTypeIndicators"
              v-model="typeIndicatorId"
              item-text="name"
              item-value="id"
              label="Tipo de indicador"
              @change="loadClassificationIndicator"
            >
            </v-select>
          </v-col>
          <v-col cols="9">
            <v-select
              outlined
              dense
              :items="listClassificationIndicators"
              v-model="classificationIndicatorId"
              item-text="titleDescription"
              item-value="id"
              label="Objetivo"
              @change="loadReports"
            >
            </v-select>
          </v-col>
          <v-col cols="3">
              <v-select
              outlined
              dense
              :items="listYears"              
              item-text="year"
              v-model="yearByFilter"              
              label="Filtro por año"            
              @change="filterByYear"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row v-if="!loading">
          <v-col cols="12" v-for="(chart, index) in charts" :key="index">
            <div
              style="
                overflow-x: scroll;
                overflow-y: hidden;
                width: 100% !important;
              "
            >
              <h3>{{ chart.title }}</h3>
              <apexchart
                width="800"
                type="bar"
                :options="chart.options"
                :series="chart.series"
              ></apexchart>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { IndicatorService } from "../../services/models/indicator";
import { RegisterIndicatorService } from "../../services/models/register_indicator";
import { TypeIndicatorService } from "../../services/models/typeIndicator";
import { ClassificationIndicatorService } from "../../services/models/classificationIndicator";
import { UserService } from "../../services/models/user";
export default {
  props: ["filterByUser"],
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      listTypeIndicators: [],
      listClassificationIndicators: [],
      listYears:[],
      yearByFilter:null,
      typeIndicatorId: null,
      classificationIndicatorId: null,
      loading: true,
      listIndicators: [],
      user: null,
      charts: [],
      listCategoriesX:[],
      listCategoriesBack:[],
      month: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      options: {
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
          ],
        },
      },
      series: [
        {
          name: "Valor",
          data: [30, 40, 45, 50, 49, 60, 30, 40, 45, 50, 49, 60],
        },
      ],
    };
  },
  methods: {
    filterByYear(){
      if(this.yearByFilter!="Todos"){
        this.loadReports(true);
      }else{
        this.loadReports();
      }
    },
    async loadClassificationIndicator() {
      this.listClassificationIndicators = (
        await ClassificationIndicatorService.findTypeId(this.typeIndicatorId)
      ).map((value) => ({
        ...value,
        titleDescription: `${value.name} - ${value.description}`,
      }));
      this.listClassificationIndicators.push({
        id: 0,
        titleDescription: "Todos",
      });
    },
    async loadReports(filter) {
      this.charts = [];
      this.loading = true;
      if (this.classificationIndicatorId == 0) {
        this.listIndicators = await IndicatorService.getAll();
      } else {
        this.listIndicators = await IndicatorService.findClassificationId(
          this.classificationIndicatorId
        );
      }
      for (let i = 0; i < this.listIndicators.length; i++) {
        const indicator = this.listIndicators[i];
        if (this.filterByUser) {
          //Si es perfil administrativo se revisa que indicadores debe ver
          let indicatorsUsers = this.user.indicators_users.map(
            (indicatorUser) => indicatorUser.indicator_id
          );
          if (!indicatorsUsers.includes(indicator.id)) {
            continue;
          }
        }
        let registerIndicators = await RegisterIndicatorService.getByIndicatorId(
          indicator.id
        );        
        let categories = [];
        let series = [];

        /**
         * Se modifico para no limitar el rangos de las fechas, por ende ya no se cargan desde un array, sino, se agrego un if para realizar el push de categorization
         */
        for (let j = 0; j < registerIndicators.length; j++) {
          const registerIndicator = registerIndicators[j];

          /** Primero se busca si ya existe X registro en X mes y en X año */
          let filterCategories = categories.find(
            (register) =>
              register.month == registerIndicator.month &&
              register.year == registerIndicator.year
          );          
          if (!filterCategories) {    

            /** Si aun no se ingresa un registro correspondiente a X mes y a X año, se realiza el push */  
            categories.push({
              month: registerIndicator.month,
              year: registerIndicator.year,
              result: registerIndicator.result,             
            });            
          } else {         

            /** En caso de que ya existiese un registro en X mes y X año, se sobrescribe el ya existente en el arreglo
             *  Dado que se toma en cuenta el ultimo registro de x mes y x año
             *  */       
            categories = categories.map(category => {
              if (category.month == registerIndicator.month && category.year == registerIndicator.year) {
                return {
                  month: registerIndicator.month,
                  year: registerIndicator.year,
                  result: registerIndicator.result,
                };
              } else {
                return category;
              }
            });            
          }
        }

        //Ordenamiento de Meses
        const sorter = (a, b) => {
          if (a.year !== b.year) {
            return a.year - b.year;
          } else {
            return this.month.indexOf(a.month) - this.month.indexOf(b.month);
          }
        };
        categories.sort(sorter);       
        this.listCategoriesX = [];
        
        /** Una vez ordenado, se guarda el array ordenado en una lista, para posteriormente usarlo en el Chart */
        categories.forEach((category) => {

          /** Se filtra por año */
          if(filter){
            if(category.year==this.yearByFilter){
              this.listCategoriesX.push(`${category.month}-${category.year}`);          
              this.listCategoriesBack.push(`${category.month}-${category.year}-${category.result}`);          
              series.push(category.result)
            }           
          }else{
            this.listCategoriesX.push(`${category.month}-${category.year}`);          
            this.listCategoriesBack.push(`${category.month}-${category.year}-${category.result}`);
            series.push(category.result)
          }
          
        });        
        this.pushChart(indicator,series);
      }
      this.loading = false;
    },
    pushChart(indicator,series){
      this.charts.push({
          title: indicator.name,
          options: {
            chart: {
              id: `vuechart-example-${indicator.id}`,
            },
            xaxis: {
              categories: this.listCategoriesX,
            },
          },
          series: [
            {
              name: "Valor",
              data: series,
            },
          ],
        });
    },

  },
  async mounted() {
    this.listYears=[];
    this.listYears.push({
      index:0,
      year:'Todos'
    })
    for (let index = 2000; index < 2041; index++) {      
      this.listYears.push({
        index:index,
        year:index
      })        
    }
    this.loading = true;
    if (this.filterByUser) {
      this.user = await UserService.getById(this.getCurrentUserLoggedIn().id);
    }
    this.listTypeIndicators = await TypeIndicatorService.getAll();
    this.typeIndicatorId = this.listTypeIndicators[0].id;
    this.listClassificationIndicators = (
      await ClassificationIndicatorService.findTypeId(
        this.listTypeIndicators[0].id
      )
    ).map((value) => ({
      ...value,
      titleDescription: `${value.name} - ${value.description}`,
    }));
    this.classificationIndicatorId = this.listClassificationIndicators[0].id;
    this.listClassificationIndicators.push({
      id: 0,
      titleDescription: "Todos",
    });
    await this.loadReports();
    this.loading = false;
  },
};
</script>