<template>
  <v-dialog v-model="dialog" max-width="600px" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        fab
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
        @click="mostrarModal"
      >
        <v-icon dark> mdi-plus </v-icon>
      </v-btn>
    </template>
      <form @submit.prevent="saveData()" ref="form">
    <v-card>
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title>Crear indicador</v-toolbar-title>
        </v-toolbar>
        <v-card-text style="height: 100%">
          <v-overlay :value="loading" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-card-text>
            <v-text-field
              outlined
              dense
              prepend-inner-icon="mdi-card-text"
              label="Nombre"
              v-model="indicator.name"
              :rules="fieldRules"
              required
            />
            <v-select
              outlined
              dense
              :rules="selectRules"
              prepend-inner-icon="mdi-view-list"
              :items="listClassificationIndicator"
              item-text="name"
              item-value="id"
              label="Clasificación de indicador"
              v-model="indicator.classification_indicator_id"
            />
            <v-text-field
              outlined
              dense
              prepend-inner-icon="mdi-calendar-range"
              label="Año"
              type="number"
              v-model="indicator.year"
              :rules="fieldRules"
              required
            />
            <v-text-field
              outlined
              dense
              prepend-inner-icon="mdi-android-studio"
              label="Formula"
              v-model="indicator.formula"
              :rules="fieldRules"
              required
            />
            <v-text-field
              outlined
              dense
              prepend-inner-icon="mdi-bullseye-arrow"
              label="Objetivo"
              v-model="indicator.goal"
              :rules="fieldRules"
              required
            />
            <v-text-field
              outlined
              dense
              prepend-inner-icon="mdi-counter"
              label="Ponderación"
              v-model="indicator.weighting"
              :rules="fieldRules"
              required
            />
            <v-text-field
              outlined
              dense
              prepend-inner-icon="mdi-check-decagram"
              label="Medio verificación"
              v-model="indicator.half_verification"
              :rules="fieldRules"
              required
            />
            <v-text-field
              outlined
              dense
              prepend-inner-icon="mdi-dice-multiple"
              label="Supuestos"
              v-model="indicator.assumptions"
              :rules="fieldRules"
              required
            />
            <v-text-field
              outlined
              dense
              prepend-inner-icon="mdi-message-bulleted"
              label="Nota de indicador"
              v-model="indicator.indicator_note"
              :rules="fieldRules"
              required
            />
             <v-select
              outlined
              dense              
              prepend-inner-icon="mdi-file-tree"
              :items="listIndicator"
              item-text="name"
              item-value="id"
              label="Indicador relacionada"
              v-model="indicator.sub_indicator_id"
            />
          </v-card-text>
        </v-card-text>
        <v-card-actions flat>
          <v-btn @click="dialog = false">Cerrar</v-btn>
          <v-btn color="primary" type="submit"
            ><v-icon left small> mdi-content-save </v-icon> Guardar</v-btn
          >
        </v-card-actions>
    </v-card>
      </form>
  </v-dialog>
</template>
<script>
import { eventBus } from "../../../main";
import { ClassificationIndicatorService } from "../../../services/models/classificationIndicator";
import { IndicatorService } from "../../../services/models/indicator";

export default {
  data() {
    return {
      listClassificationIndicator: [],
      listIndicator:[],

      dialog: false,
      loading: false,
      fieldRules: [(v) => !!v || "Este campo es obligatorio"],
      selectRules: [(v) => !!v || "Debe seleccionar una opción"],

      indicator: {
        name: null,
        classification_indicator_id: null,
        year: null,
        formula: null,
        goal: null,
        weighting: null,
        half_verification: null,
        assumptions: null,
        indicator_note: null,
        user_id: JSON.parse(localStorage.getItem("user")).id,
        sub_indicator_id:null,
      },
    };
  },
  methods: {
    async mostrarModal() {
      this.dialog = true;
    },
    async loadData() {
      this.loading = true;
      this.listClassificationIndicator = await ClassificationIndicatorService.getAll();
      this.listIndicator=await IndicatorService.getAll();
      this.listIndicator.push({
        name:'Sin Indicador Relacionado',
        id:null,
      })
      this.loading = false;
    },
    async saveData() {
      try {
        this.loading = true;
        await IndicatorService.create(this.indicator);
        eventBus.$emit("loadIndicators");
         this.showAlert("showNotification", {
          text: "Se creo el indicador",
          color: "success",
        });   
        this.dialog = false;
        this.$refs.form.reset();
      } catch (error) {
        console.log(error);
        eventBus.$emit("showNotification", "error al crear indicador", "error");
         this.showAlert("showNotification", {
          text: "error al crear indicador",
          color: "error",
        });   
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.loadData();
      } else {
        this.$refs.form.reset();
      }
    },
  },
};
</script>