<template>
  <PlantillaHome>
    <div slot="contenido">
      <v-card  elevation="10" class="d-flex  pa-4">
        <!-- <v-btn elevation="10" color="primary" fab><v-icon dark> mdi-plus </v-icon></v-btn>         -->
    <v-card-text style="height: 100%">
    <v-card-title>{{listIndicator.classification_indicator.name}}: {{listIndicator.classification_indicator.description}}</v-card-title>
        {{listIndicator.name}} 
      <v-row>
          <v-col cols="12">
            <v-overlay :value="loading" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <strong>

            </strong>

          </v-col>
      </v-row>
      <v-row>
          <v-col cols="12">
              <table-registers :indicator_id="id_item"  />                
          </v-col>
      </v-row>
           

        </v-card-text>
      </v-card>
    </div>
  </PlantillaHome>
</template>
<script>
import PlantillaHome from "../../../templates/PlantillaHome";
import { IndicatorService } from '../../../services/models/indicator';
import TableRegisters from '../../../components/management_control/register/TableRegisters.vue';
export default {
  components: {
    PlantillaHome,
    TableRegisters,    
        
  },
   data() {
    return {
      dialog: false,
      loading: false,
      id_item:this.$route.params.id,
      listIndicator:[],
    };
  },
  mounted(){
      this.loadData();
  },
   methods: {
    
    async mostrarModal() {
      this.dialog = true;
    },
    async loadData() {
      this.loading = true;
      Promise.all([
        IndicatorService.getById(this.id_item)
      ]).then((response) => {
        this.listIndicator = response[0];
      }).catch((err) => {
        console.log(err);
        this.showAlert({
          color: "error",
          text: "Ha ocurrido un error al cargar los datos, favor interntar más tarde"
        });
      }).finally(() => {
        this.loading = false;
      })
    },
  },
};
</script>