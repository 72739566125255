<template>
  <v-dialog v-model="dialog" max-width="1000px" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        x-small
        fab
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
        @click="mostrarModal"
      >
        <v-icon dark> mdi-eye </v-icon>
       
      </v-btn>
    </template>
   <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="11">
        Listado de Notas     

          </v-col>
          <v-col cols="1">
           <create-note :register_indicator_id="this.register_indicator_id"></create-note>

          </v-col>
        </v-row>
        
</v-card-title>
      <v-card-text style="height: 100%">
        <v-overlay :value="loading" absolute>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    <!-- <v-row>
      <v-col class="text-right">

    <create-note :register_indicator_id="this.register_indicator_id"></create-note>
      </v-col>
    </v-row> -->
     <v-timeline
      :reverse="reverse"
      dense
    >
      <v-timeline-item
        v-for="n in listNote"
        :key="n.id"
      >
        <span slot="opposite"  v-text="n.user.name">dasdasdsa</span>
        <v-card class="elevation-2">
          <v-card-title class="headline">
           {{n.user.name}} {{n.user.pather_lastname}} 
          </v-card-title>
          <v-card-subtitle>
            {{n.created_at}}
          </v-card-subtitle>
          <v-card-text>
            {{n.note}}
          </v-card-text>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  
      </v-card-text>
      <v-card-actions flat>
        <v-btn @click="dialog = false" color="secondary">Cerrar</v-btn>
      

     
      </v-card-actions>

    </v-card>
    
  </v-dialog>
</template>
<script>
import { NoteService } from "../../../services/models/note";
import CreateNote from "../notes/Create"
import { eventBus } from "../../../main";

export default {
  props: ["register_indicator_id"],
  components:{
    CreateNote
  },
  data() {
    return {
      dialog: false,
      loading: false,
      userIndicators: [],
      listNote:[],
      fieldRules: [(v) => !!v || "Este campo es obligatorio"],
      selectRules: [(v) => !!v || "Debe seleccionar una opción"],
      tab: null,
       reverse: true,
      search:'',
    
    };
  },
  
  methods: {
    
  
    async mostrarModal() {
      this.dialog = true;
    },
    async loadData() {
      this.loading = true;
      Promise.all([
        NoteService.findByRegisterIndicatorId(this.register_indicator_id),
      ]).then((response) => {
        this.listNote = response[0];
      }).catch((err) => {
        console.log(err);
        this.showAlert({
          color: "error",
          text: "Ha ocurrido un error al cargar los datos, favor interntar más tarde"
        });
      }).finally(() => {
        this.loading = false;
      })
    },
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.loadData();
      } else {
        this.$refs.form.reset();
      }
    eventBus.$on('loadNotes',()=>{
      this.loadData()
    })
    },
    
  },
};
</script>