<template>
  <div style="width: 100%">
    <v-data-table      
      :headers="headers"
      :items="listTypesIndicators"
      item-key="rut"
      class="elevation-1"
      :loading="loading"
      loading-text="Cargando... Favor de esperar"
      :search="search"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Tipo de Indicadores</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <create/>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-row align="center" >
            <edit :typeIndicatorData="item"/>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Create from './Create.vue';
import { TypeIndicatorService } from '../../../services/models/typeIndicator'; 
import { eventBus } from '../../../main';
import Edit from './Edit.vue';

export default {
  components: {
    Create,
    Edit
    
  },
  data() {
    return {
      search: "",
      listTypesIndicators: [],
      loading: false,
      headers: [
        { text: "Nombre", value: "name", align: "start", sortable: true },
        { text: "Acciones", value: "actions", sortable: true },
      ],
    };
  },
  methods: {
    async chargelistTypesIndicators() {
      this.loading = true;
      this.listTypesIndicators =await TypeIndicatorService.getAll();
      this.loading = false;
    },
  },
  mounted() {
    this.chargelistTypesIndicators();
    eventBus.$on('loadTypeIndicator',()=>{
      this.chargelistTypesIndicators();
    })
  },
};
</script>