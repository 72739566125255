import Axios from "axios";
import {
    BaseService
} from "./../base";
export class UserService extends BaseService {
    static get entity() {
        return "user";
    }
    static get name() {
        return "user";
    }

    static async login(data) {
        return Axios.post(`${process.env.VUE_APP_API_URL}/api/auth`, data);
    }
    static async new_passwd(data) {
        return this.submit(`${process.env.VUE_APP_API_URL}/api/${this.entity}/new_passwd`, data);
    }
}