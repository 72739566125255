<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="error"
          elevation="5"          
          icon
          dark
          v-bind="attrs"
          v-on="on"
        >
          <v-icon dark>mdi-trash-can</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Eliminar Indicador
        </v-card-title>
        <v-card-text>
          <v-overlay :value="loading" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          ¿Esta seguro de eliminar el indicador <b>{{ indicator.name }}</b
          >?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="dialog = false"> Cerrar </v-btn>
          <v-btn color="error" @click="deleteIndicator()"> Aceptar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { eventBus } from '../../../main';
import { IndicatorService } from '../../../services/models/indicator';

export default {
  props: ["indicator"],
  data() {
    return {
      dialog: false,
      loading: false,
    };
  },
  methods: {
    async deleteIndicator() {
      this.loading = true;
      await IndicatorService.remove(this.indicator.id);
      eventBus.$emit("loadIndicators");
      eventBus.$emit('loadSubIndicators');
      this.loading = false;
      this.dialog = false;
    },
  },
};
</script>