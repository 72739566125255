var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[(_vm.description)?_c('v-card-title',[_vm._v(" "+_vm._s(_vm.description.name ? _vm.description.name +":" : '')+" "+_vm._s(_vm.description.description ? _vm.description.description : '')+" ")]):_vm._e(),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.listUsers,"item-key":"id","loading":_vm.loading,"loading-text":"Cargando... Favor de esperar","search":_vm.search,"show-expand":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Indicadores")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer')],1)]},proxy:true},(!_vm.isAdministrative)?{key:"item.create",fn:function(ref){
var item = ref.item;
return [_c('create-register',{attrs:{"indicator_id":item.id}})]}}:null,{key:"item.viewList",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
                  name: "viewRegister",
                  params: { id: item.id },
                }}},[_c('v-btn',{attrs:{"x-small":"","fab":"","color":"primary","dark":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-eye ")])],1)],1)]}},{key:"expanded-item",fn:function(ref){
                var headers = ref.headers;
                var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('DetailTableIndicators',{attrs:{"detail":item,"isAdministrative":_vm.isAdministrative}})],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }