import {
    BaseService
} from '../base'
export class NoteService extends BaseService {
    static get entity() {
        return 'note'
    }
    static get name() {
        return 'note'
    }
    static async findByRegisterIndicatorId(id) {
        return this.submit("get", `${this.profile}/${this.entity}/find_by_register_indicator_id/${id}`, this.name);
    }
}