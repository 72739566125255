<template>
  <div style="width: 100%">
    <v-data-table      
      :headers="headers"
      :items="listIndicators"
      item-key="id"
      class="elevation-1"
      :loading="loading"
      loading-text="Cargando... Favor de esperar"
      :search="search"
      show-expand
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Indicadores</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <create/>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-row align="center" >
          <edit :indicatorData="item" />
          <delete :indicator="item" />
        </v-row>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">       
          <detail-table-indicators :detail="item"/>
      </td>      
    </template>
    </v-data-table>
  </div>
</template>

<script>
import Create from './Create.vue';
import { IndicatorService } from '../../../services/models/indicator'
import { eventBus } from '../../../main';
import Edit from './Edit.vue';
import Delete from './Delete.vue';
import DetailTableIndicators from '../../management_control/indicators/DetailTableIndicators.vue';

export default {
  components: {
    Create,
    Edit,
    Delete,
    DetailTableIndicators,
    
  },
  data() {
    return {
      search:"",    
      listIndicators: [],
      loading: false,
      headers: [
        { text: "Nombre", value: "name", align: "start", sortable: true },
        { text: "Acciones", value: "actions", sortable: true },
      ],
    };
  },
  methods: {
    async chargelistIndicators() {
      this.loading = true;
      this.listIndicators =await IndicatorService.getAll();
      this.loading = false;
    },
  },
  mounted() {
    this.chargelistIndicators();
    eventBus.$on('loadIndicators',()=>{
      this.chargelistIndicators();
    })
  },
};
</script>