var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('v-select',{attrs:{"outlined":"","dense":"","items":_vm.listTypeIndicators,"item-text":"name","item-value":"id","label":"Tipo de indicador","loading":_vm.loading},on:{"change":_vm.loadClassificationIndicator},model:{value:(_vm.typeIndicatorId),callback:function ($$v) {_vm.typeIndicatorId=$$v},expression:"typeIndicatorId"}}),_c('v-select',{attrs:{"outlined":"","dense":"","items":_vm.listClassificationIndicators,"item-text":"titleDescription","item-value":"id","label":"Objetivo","loading":_vm.loading},on:{"change":_vm.loadIndicators},model:{value:(_vm.classificationIndicatorId),callback:function ($$v) {_vm.classificationIndicatorId=$$v},expression:"classificationIndicatorId"}}),_c('v-select',{attrs:{"outlined":"","dense":"","items":_vm.listIndicators,"item-text":"name","item-value":"id","loading":_vm.loading,"label":"Indicador"},model:{value:(_vm.indicatorId),callback:function ($$v) {_vm.indicatorId=$$v},expression:"indicatorId"}}),_c('v-file-input',{attrs:{"show-size":"","label":"Resolución adjunta"},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('v-btn',{attrs:{"loading":_vm.loading,"elevation":"2","color":"teal","dark":""},on:{"click":_vm.addIndicator}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Agregar ")],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":[
        {
          text: 'Indicador',
          value: 'indicator.name',
        },
        {
          text: 'Acciones',
          value: 'actions',
          width: '120px',
        } ],"items":_vm.userIndicators,"item-key":"rut","no-data-text":"No hay indicadores ingresados","loading-text":"Cargando... Favor de esperar"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-row',{attrs:{"align":"center"}},[_c('v-btn',{staticClass:"white--text",staticStyle:{"margin-right":"1em"},attrs:{"small":"","color":"teal","fab":"","disabled":!item.file},on:{"click":function($event){return _vm.openPreview(item.file)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye ")])],1),_c('v-btn',{attrs:{"loading":_vm.loadingDelete && _vm.deleteSelected == item.id,"small":"","color":"error","fab":""},on:{"click":function($event){return _vm.deleteIndicator(item.id, item.indicator_id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete ")])],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }