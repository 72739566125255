import { BaseService } from './../base'
export class FileRegisterIndicatorService extends BaseService {
    static get entity () {
        return 'file_register_indicator'
    }
    static get name () {
        return 'file_register_indicator'
    }
    static async findByRegisterIndicatorId (id) {
        return this.submit('get', `${this.profile}/${this.entity}/find_by_register_indicator_id/${id}`, this.name);
      }
}