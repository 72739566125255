<template>
  <PlantillaHome>
    <div slot="contenido">
      <v-card elevation="10" class="d-flex pa-4">
        <!-- <v-btn elevation="10" color="primary" fab><v-icon dark> mdi-plus </v-icon></v-btn>         -->        
        <indicators-list/>
      </v-card>
    </div>
  </PlantillaHome>
</template>
<script>
import IndicatorsList from '../../../../components/manteiners/indicators/IndicatorsList.vue';
import PlantillaHome from "../../../../templates/PlantillaHome";
export default {
  components: {
    PlantillaHome,
    IndicatorsList,    
  },
  data() {
    return {
      
    };
  },
  methods: {},
};
</script>