<template>
  <PlantillaHome>
    <div slot="contenido">
      <v-card  elevation="10" class="d-flex  pa-4">
        <!-- <v-btn elevation="10" color="primary" fab><v-icon dark> mdi-plus </v-icon></v-btn>         -->
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <clasification-indicator-filter></clasification-indicator-filter>
                </v-col>    
            </v-row> 
            
            <v-row>
                <v-col cols="12">
                    <indicator-list-management></indicator-list-management>
                </v-col>
            </v-row>

        </v-card-text>
      </v-card>
    </div>
  </PlantillaHome>
</template>
<script>
import PlantillaHome from "../../../templates/PlantillaHome";
import IndicatorListManagement from "../../../components/management_control/indicators/IndicatorListManagement"
import ClasificationIndicatorFilter from '../../../components/management_control/indicators/ClasificationIndicatorFilter.vue';
export default {
  components: {
    PlantillaHome,
    IndicatorListManagement,
    ClasificationIndicatorFilter
        
  },
  data() {
    return {
      
    };
  },
  methods: {},
};
</script>