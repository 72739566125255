<template>
  <v-dialog v-model="dialog" max-width="600px" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn x-small fab color="primary" dark v-bind="attrs" v-on="on">
        <v-icon small> mdi-pencil </v-icon>
      </v-btn>
    </template>
    <v-form v-on:submit.prevent="saveData" ref="form">
      <v-card>
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title>Modificar usuario</v-toolbar-title>
        </v-toolbar>
        <v-card-text style="height: 100%">
          <v-overlay :value="loading" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-card-text>
            <v-tabs v-model="tab">
              <v-tab><v-icon left> mdi-account </v-icon>Datos</v-tab>
              <v-tab
                ><v-icon left> mdi-chart-bell-curve-cumulative </v-icon
                >Indicadores</v-tab
              >
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-card>
                  <v-card-text>
                    <v-text-field
                      outlined
                      dense
                      prepend-inner-icon="mdi-account"
                      label="Nombre"
                      v-model="user.name"
                      :rules="fieldRules"
                      required
                    />
                    <v-text-field
                      outlined
                      dense
                      prepend-inner-icon="mdi-account"
                      label="Apellido Paterno"
                      :rules="fieldRules"
                      v-model="user.pather_lastname"
                      required
                    />
                    <v-text-field
                      outlined
                      dense
                      prepend-inner-icon="mdi-account"
                      label="Apellido Materno"
                      :rules="fieldRules"
                      v-model="user.mother_lastname"
                      required
                    />
                    <v-text-field
                      outlined
                      dense
                      prepend-inner-icon="mdi-at"
                      label="Email"
                      :rules="fieldRules"
                      v-model="user.email"
                      required
                    />
                    <v-text-field
                      outlined
                      dense
                      prepend-inner-icon="mdi-account"
                      label="Rut"
                      :rules="fieldRules"
                      v-model="user.run"
                      required
                    />
                    <v-select
                      outlined
                      dense
                      :rules="selectRules"
                      prepend-inner-icon="mdi-briefcase"
                      :items="listStablishment"
                      v-model="user.stablishment_id"
                      item-text="name"
                      item-value="id"
                      label="Seleccionar Establecimiento"
                    >
                    </v-select>
                    <v-select
                      outlined
                      dense
                      :rules="selectRules"
                      prepend-inner-icon="mdi-home-circle"
                      :items="listRoles"
                      v-model="user.role_id"
                      item-text="name"
                      item-value="id"
                      label="Seleccionar Rol"
                    >
                    </v-select>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <edit-user-indicators :userId="userData.id" :indicatorsUsers="user.indicators_users" />
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card-text>
        <v-card-actions flat>
          <v-btn @click="dialog = false">Cerrar</v-btn>
          <v-btn color="primary" type="submit"
            ><v-icon left small> mdi-content-save </v-icon> Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { eventBus } from "../../../main";
import { RoleService } from "../../../services/models/role";
import { StablishmentService } from "../../../services/models/stablishment";
import { UserService } from "../../../services/models/user";
import EditUserIndicators from './EditUserIndicators.vue';
export default {
  components: { EditUserIndicators },
  props: ["userData"],
  data() {
    return {
      dialog: false,
      loading: false,
      listStablishment: [],
      listRoles: [],
      userIndicators: [],
      fieldRules: [(v) => !!v || "Este campo es obligatorio"],
      selectRules: [(v) => !!v || "Debe seleccionar una opción"],
      tab: null,
      user: {
        name: null,
        pather_lastname: null,
        mother_lastname: null,
        run: null,
        email: null,
        password: null,
        role_id: 1,
        stablishment_id: 1,
      },
    };
  },
  methods: {
    async mostrarModal() {
      this.dialog = true;
    },
    async loadData() {
      this.loading = true;
      Promise.all([
        RoleService.getAll(),
        StablishmentService.getAll(),
        UserService.getById(this.userData.id)
      ]).then((response) => {
        this.listRoles = response[0];
        this.listStablishment = response[1];
        this.user = response[2];
      }).catch((err) => {
        console.log(err);
        this.showAlert({
          color: "error",
          text: "Ha ocurrido un error al cargar los datos, favor interntar más tarde"
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    async saveData() {
      try {
        this.loading = true;
        await UserService.update(this.userData.id, this.user);
        eventBus.$emit("loadTableUsers");
        this.showAlert({
          text: "El usuario se ha modificado correctamente",
          color: "success",
        });
      } catch (error) {
        console.log(error);
        this.showAlert({
          text: "error al editar",
          color: "error",
        });
      } finally {
        this.dialog = false;
        this.loading = false;
      }
    },
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.loadData();
      }
    },
  },
};
</script>