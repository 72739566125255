<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn text block color="white" dark v-bind="attrs" v-on="on">
          Cerrar Sesión
        </v-btn>
      </template>

      <v-card>
        <v-card-title> Cerrar Sesión </v-card-title>
        <v-card-text> ¿Esta seguro de querrer cerrar su sesión? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false"> Cerrar </v-btn>
          <v-btn color="error" @click="close"> Confirmar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    close() {
      localStorage.clear();
      this.$router.push("/");
    },
  },
};
</script>