<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="error"
          elevation="5"
          outlined
          icon
          dark
          v-bind="attrs"
          v-on="on"
        >
          <v-icon dark>mdi-trash-can</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Eliminar Archivo
        </v-card-title>
        <v-card-text>
          <v-overlay :value="loading" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          ¿Esta seguro de eliminar el archivo <b>{{ fileRegister.file_name }}</b
          >?, una vez eliminado no podra recuperarlo
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="dialog = false"> Cerrar </v-btn>
          <v-btn color="error" @click="deleteRegister()"> Aceptar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { eventBus } from "../../main";
import { FileRegisterIndicatorService } from "../../services/models/fileRegisterIndicator";
export default {
  props: ["fileRegister"],
  data() {
    return {
      dialog: false,
      loading: false,
    };
  },
  methods: {
    async deleteRegister() {
      this.loading = true;
      await FileRegisterIndicatorService.remove(this.fileRegister.id);
      eventBus.$emit("loadTableFilesRegisterIndicators");
      this.loading = false;
      this.dialog = false;
    },
  },
};
</script>