<template>
  <v-dialog v-model="dialog" max-width="800px" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        x-small
        fab
        color="info"
        dark
        v-bind="attrs"
        v-on="on"
        @click="mostrarModal"
      >
        <v-icon dark> mdi-history </v-icon>       
      </v-btn>
    </template>
   <v-card>
      <v-card-title>Registro de modificaciones</v-card-title>
      <v-card-text style="height: 100%">
        <v-overlay :value="loading" absolute>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    <list-modifications-register :listRegister="listRegister" />
      </v-card-text>
      <v-card-actions flat>
        <v-btn @click="dialog = false" color="secondary">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
    
  </v-dialog>
</template>
<script>
import { RegisterIndicatorHistoryService } from '../../../services/models/registerIndicatorHistory';
import ListModificationsRegister from './ListModificationsRegister.vue';
export default {
  components: { ListModificationsRegister },
  props: ["register_indicator"], 
  data() {
    return {
      dialog: false,
      loading: false,
      listRegister:[],
    };
  },
  methods: {  
    async mostrarModal() {
      this.dialog = true;
    },
    async loadData() {
      this.loading = true;
      this.listRegister=await RegisterIndicatorHistoryService.findByRegisterIndicatorId(this.register_indicator.id);
      this.loading = false;     
    },
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.loadData();
      } 
    },    
  },
};
</script>