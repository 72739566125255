<template>
  <div style="width: 100%">
    <v-card-title v-if="description">
     {{description.name ? description.name +":" : ''}} {{description.description ? description.description : ''}}
    </v-card-title>
    <v-data-table      
      :headers="headers"
      :items="listUsers"
      item-key="id"
      class="elevation-1"
      :loading="loading"
      loading-text="Cargando... Favor de esperar"
      :search="search"
      show-expand
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Indicadores</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <template v-if="!isAdministrative" v-slot:[`item.create`]="{ item }">
        <create-register :indicator_id="item.id"></create-register>   
      </template>
      <template v-slot:[`item.viewList`]="{ item }">
         <router-link
                  :to="{
                    name: `viewRegister`,
                    params: { id: item.id },
                  }"
                >
                  <v-btn
                    x-small
                    fab
                    color="primary"
                    dark                                     
                   
                  >
               <v-icon dark> mdi-eye </v-icon>
                   </v-btn>
                </router-link>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">       
          <DetailTableIndicators :detail="item" :isAdministrative="isAdministrative" />
      </td>
      
    </template>
   

    </v-data-table>
  </div>
</template>

<script>
import CreateRegister from "../../../components/management_control/register/Create";

import { IndicatorService } from '../../../services/models/indicator';
import { ClassificationIndicatorService } from "../../../services/models/classificationIndicator"
import {eventBus} from '../../../main';
import { UserService } from '../../../services/models/user';
import DetailTableIndicators from './DetailTableIndicators';

export default {
  props: ["isAdministrative"],
  components: {
   CreateRegister,
   DetailTableIndicators
  },
  data() {
    return {
      search: "",
      listUsers: [],
      loading: false,
      classification_id:null,
      description:null,
      indicators_users: null,
      headers: [
        { text: "Nombre", value: "name", align: "start", sortable: true },
        { text: "Responsable", value: "user.name", sortable: true },
        { text: "Nuevo", value: "create", sortable: true, align: `${this.isAdministrative ? ' d-none' : ''}` },
        { text: "Detalle", value: "viewList", sortable: true },
      ],
        expanded: [],
        singleExpand: true,
    };
  },
  methods: {
    async chargeListUsers() {
      this.loading = true;
      this.listUsers = await IndicatorService.getBySubIndicatorNull();
      if (this.isAdministrative) {
        this.listUsers = this.listUsers.filter((indicator) => this.indicators_users.includes(indicator.id));
      }
      this.loading = false;
    },
    async chargeListIndicator(){
      this.loading = true;
      this.listUsers = await IndicatorService.findClassificationId(this.classification_id);
      if (this.isAdministrative) {
        this.listUsers = this.listUsers.filter((indicator) => this.indicators_users.includes(indicator.id));
      }
      this.description = await ClassificationIndicatorService.getById(this.classification_id)
      this.loading = false;
    },
    async loadFindSubIndicator(id){
      this.loading=true;
      this.listUsers=await IndicatorService.findSubIndicator(id);
      this.loading=false;
    }
  },
  async mounted() {
    if (this.isAdministrative) {
      this.indicators_users = (await UserService.getById(this.getCurrentUserLoggedIn().id)).indicators_users.map(value => value.id);
      console.log(this.indicators_users);
    }
    this.chargeListUsers();
    eventBus.$on('loadClassification',(id)=>{
      this.classification_id = id;
      this.chargeListIndicator()
    });
    eventBus.$on('loadFindSubIndicator',(id)=>{      
      this.loadFindSubIndicator(id);
    })
  },
};
</script>