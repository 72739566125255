<template>
  <v-data-table
    :headers="headers"
    :items="listRegister"
    item-key="created_at"
    class="elevation-2"
    :loading="loading"
    loading-text="Cargando... Favor de esperar"    
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Historial de modificaciones</v-toolbar-title>               
      </v-toolbar>
    </template>
  </v-data-table>
</template>
<script>
export default {
    props:['listRegister'],
  data() {
    return {      
      headers: [
        { text: "Responsable", value: "user.name",  },
        { text: "Mes", value: "month"},
        { text: "Año", value: "year"},
        {text: "Numerador",value: "numerator"},
        { text: "Denominador", value: "denominator"},
        { text: "Resultado", value: "result"},
        { text: "Fecha", value: "created_at"},
      ],
    };
  },
};
</script>