<template>
  <v-dialog v-model="dialog" max-width="600px" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        x-small
        fab
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
        @click="mostrarModal"
      >
        <v-icon dark> mdi-pencil </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Crear tipo de indicador</v-card-title>
      <v-card-text style="height: 100%">
        <v-overlay :value="loading" absolute>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card-text>
          <v-form>
            <v-text-field
              outlined
              dense
              v-model="type_indicator.name"
              prepend-inner-icon="mdi-list-status"
              label="Tipo de Indicador"
              :rules="fieldRules"
              required
            />
          </v-form>
        </v-card-text>
      </v-card-text>
      <v-card-actions flat>
        <v-btn @click="dialog = false" color="secondary">Cerrar</v-btn>
        <v-btn color="primary" @click="saveData()"
          ><v-icon left small> mdi-content-save </v-icon> Guardar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { eventBus } from "../../../main";
import { TypeIndicatorService } from "../../../services/models/typeIndicator";

export default {
  props: ["typeIndicatorData"],
  data() {
    return {
      dialog: false,
      loading: false,
      fieldRules: [(v) => !!v || "Este campo es obligatorio"],
      type_indicator: {
        name: null,
      },
    };
  },
  methods: {
    async mostrarModal() {
      this.dialog = true;
    },
    async saveData() {
      try {
        this.loading = true;
        await TypeIndicatorService.update(this.typeIndicatorData.id,this.type_indicator);
        eventBus.$emit("loadTypeIndicator");
        this.showAlert("showNotification", {
          text: "Se creo el tipo de indicador",
          color: "success",
        });
        this.dialog = false;
      } catch (error) {
        console.log(error);
        this.showAlert("showNotification", {
          text: "error al crear tipo de indicador",
          color: "error",
        });    
      } finally {
        this.loading = false;
      }
    },
    async loadData() {
      this.loading = true;
      this.type_indicator = await TypeIndicatorService.getById(
        this.typeIndicatorData.id
      );
      this.loading = false;
    },
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.loadData();
      }
    },
  },
};
</script>