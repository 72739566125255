import EmptyRouterView from './../views/administrative/EmptyRouterView.vue';
import Management_Control from './../views/management_control/Management_Control.vue';
import Register from './../views/management_control/register/Register.vue';
import ViewRegister from "./../views/management_control/register/ViewRegister.vue";
import Users from './../views/administrator/manteiners/user/Users.vue';
import TypesIndicators from './../views/administrator/manteiners/typesIndicators/TypeIndicator.vue';
import ClassificationIndicator from './../views/administrator/manteiners/classificationsIndicators/ClassificationIndicator.vue';
import Indicator from './../views/administrator/manteiners/indicators/Indicator.vue';
import Reports from './../views/administrator/ReportsView.vue';

export const routes = [{
    path: '/controlGestion',
    component: EmptyRouterView,
    children: [{
            path: 'inicio',
            name: 'manegementControlHome',
            component: Management_Control,
        },
        {
            path: 'registro',
            name: 'registerManegementControl',
            component: Register,
        },
        {
            path: 'mantenedor-usuarios',
            name: 'usersManagementControl',
            component: Users,
        },
        {
            path: 'mantenedor-Tipo_indicadores',
            name: 'typesIndicatorsManagementControl',
            component: TypesIndicators,
        },
        {
            path: 'mantenedor-clasificacion_indicadores',
            name: 'classificationIndicatorsManagementControl',
            component: ClassificationIndicator,
        },
        {
            path: 'mantenedor-Indicadores',
            name: 'IndicatorManagementControl',
            component: Indicator,
        },
        {
            path: 'reportes',
            name: 'reportsManagementControl',
            component: Reports,
        },
        {
            path: 'verRegistros/:id',
            name: 'viewRegister',
            component: ViewRegister,
            props: true,

        },
    ]
}, ]