<template>
  <v-dialog v-model="dialog" max-width="600px" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        x-small
        fab
        color="secondary"
        dark
        v-bind="attrs"
        v-on="on"
        @click="mostrarModal"
      >
        <v-icon dark> mdi-plus </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Crear Registro</v-card-title>
      <v-card-text style="height: 100%">
        <v-overlay :value="loading" absolute>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card-text>
            <v-form ref="form">
                <v-row>
                    <v-col cols="4">
                        <v-text-field
                            outlined
                            dense
                            prepend-inner-icon="mdi-calculator-variant
"
                            label="Numerador"
                            :rules="fieldRules"
                            required
                            v-model="registerData.numerator"
                        />         
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            outlined
                            dense
                            prepend-inner-icon="mdi-calculator-variant
"
                            label="Denominador"
                            :rules="fieldRules"
                            required
                            v-model="registerData.denominator"
                            @keyup="dividir"
                        />         
                    </v-col>
                    <v-col cols="4">
                        <v-text-field
                            outlined
                            dense
                            prepend-inner-icon="mdi-equal-box"
                            label="Resultado"
                            :rules="fieldRules"
                            required
                            v-model="registerData.result"
                        />         
                    </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
              <v-select
                  outlined
                  dense
                  :rules="fieldRules"
                  prepend-inner-icon="mdi-calendar"
                  :items="years"                  
                  v-model="registerData.year"
                  label="Seleccione año"
                />
            </v-col>
              <v-col cols="6">
              <v-select
                outlined
                dense
                :rules="fieldRules"
                prepend-inner-icon="mdi-calendar"
                :items="months"
                v-model="registerData.month"
                label="Seleccione mes"
              />
              </v-col>
            </v-row>
        <v-textarea
                label="Observacion"
                :rules="fieldRules"
                required
                outlined
                dense
                v-model="registerData.observations"
                prepend-inner-icon="mdi-bullseye-arrow"
        ></v-textarea>   

            <input-files ref="inputFiles"/>
            </v-form>
          </v-card-text>
      </v-card-text>
      <v-card-actions flat>
        <v-btn @click="dialog = false" color="secondary">Cerrar</v-btn>
        <v-btn color="primary" @click="saveRegister()"
          ><v-icon left small> mdi-content-save </v-icon> Guardar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { eventBus } from "../../../main";
import { FileRegisterIndicatorService } from "../../../services/models/fileRegisterIndicator";

import { RegisterIndicatorService } from "../../../services/models/register_indicator";
import { RegisterIndicatorHistoryService } from "../../../services/models/registerIndicatorHistory";
import InputFiles from "../../filesRegisterIndicators/InputFiles.vue";
export default {
  components: { InputFiles },
  props: ["indicator_id"],
  data() {
    return {
      dialog: false,
      loading: false,
      fieldRules: [(v) => !!v || "Este campo es obligatorio"],
      date:null,
      registerData:{
        numerator:0,
        denominator:0,
        month:null,
        year:new Date().getFullYear(),
        indicator_id:this.indicator_id,
        user_id:JSON.parse(window.localStorage.getItem("user")).id,
        result:0,
        observations:""
      },
        months:["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"],
        years:[]
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    dialog() {
      if (this.dialog) {
        for (let index = 2000; index < 2041; index++) {
          this.years.push(index)        
        }
        this.registerData.year=new Date().getFullYear()
      } else {
        this.$refs.form.reset();
      }
    },
  },

  methods: {
    async mostrarModal() {
      this.dialog = true;
      this.loading = true;
      this.loading = false;
    },
    save (date) {
        this.$refs.menu.save(date)
      },
    dividir(){
      this.registerData.result = (parseFloat(this.registerData.numerator) / parseFloat(this.registerData.denominator)).toFixed(3)*100 +"%"
    },
   async saveRegister(){
   
      try {
        this.loading = true;
        let response = await RegisterIndicatorService.create(this.registerData);         
        await RegisterIndicatorHistoryService.create({...response,register_indicator_id:response.id});        
        for (let i = 0; i < this.$refs.inputFiles.filesList.length; i++) {
          this.showCountdown(i, this.$refs.inputFiles.filesList.length);
          let formData = new FormData();
          formData.append("register_indicator_id", response.id);
          formData.append("file", this.$refs.inputFiles.filesList[i]);
          formData.append(
            "user_id",
            JSON.parse(localStorage.getItem("user")).id
          );
          await FileRegisterIndicatorService.create(formData, {
            "Content-Type": "multipart/form-data",
          });
        }
        this.hideCountdown();
        this.showAlert({
          color: "success",
          text: "Se creo el Registro"
        });
        eventBus.$emit("clearFilesRegisterIndicatorList");
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.showAlert({
          color: "error",
          text: "Error al crear el registro"
        });
      } finally {
        this.loading = false;
        this.dialog = false;
      }
    },
  },
};
</script>