<template>
  <v-dialog v-model="dialog" max-width="600px" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        fab
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
        @click="mostrarModal"
      >
        <v-icon dark> mdi-plus </v-icon>
      </v-btn>
    </template>
    <form @submit.prevent="saveData()" ref="form">
      <v-card>
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title>Crear clasificacion de indicador</v-toolbar-title>
        </v-toolbar>
        <v-card-text style="height: 100%">
          <v-overlay :value="loading" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-card-text>
            <v-text-field
              outlined
              dense
              prepend-inner-icon="mdi-card-text"
              label="Nombre"
              :rules="fieldRules"
              v-model="classification_indicator.name"
              required
            />
            <v-select
              outlined
              dense
              :rules="selectRules"
              prepend-inner-icon="mdi-view-list"
              :items="listTypeIndicators"
              item-text="name"
              item-value="id"
              v-model="classification_indicator.type_indicator_id"
              label="Seleccionar tipo de indicador"
            />
            <v-textarea
              outlined
              dense
              prepend-inner-icon="mdi-card-text"
              label="Observación"
              v-model="classification_indicator.description"
              :rules="fieldRules"
              required
            />
          </v-card-text>
        </v-card-text>
        <v-card-actions flat>
          <v-btn @click="dialog = false">Cerrar</v-btn>
          <v-btn color="primary" type="submit"
            ><v-icon left small> mdi-content-save </v-icon> Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </form>
  </v-dialog>
</template>
<script>
import { eventBus } from "../../../main";
import { ClassificationIndicatorService } from "../../../services/models/classificationIndicator";
import { TypeIndicatorService } from "../../../services/models/typeIndicator";

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      listTypeIndicators: [],
      fieldRules: [(v) => !!v || "Este campo es obligatorio"],
      selectRules: [(v) => !!v || "Debe seleccionar una opción"],
      classification_indicator: {
        name: null,
        type_indicator_id: 1,
        description: null,
      },
    };
  },
  methods: {
    async mostrarModal() {
      this.dialog = true;
    },
    async loadData() {
      this.loading = true;
      this.listTypeIndicators = await TypeIndicatorService.getAll();
      this.loading = false;
    },
    async saveData() {
      try {
        this.loading = true;
        await ClassificationIndicatorService.create(
          this.classification_indicator
        );
        eventBus.$emit("loadClassificationsIndicators");
        this.showAlert("showNotification", {
          text: "Se creo el clasificacion de indicador",
          color: "success",
        });
        this.dialog = false;
        this.$refs.form.reset();
      } catch (error) {
        console.log(error);
        this.showAlert("showNotification", {
          text: "error al crear clasificacion de indicador",
          color: "error",
        });
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.loadData();
      } else {
        this.$refs.form.reset();
      }
    },
  },
};
</script>