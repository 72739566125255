<template>
  <section>
    <input-files ref="inputFiles" />
    <v-btn color="primary" @click="saveFiles()"
      ><v-icon left small> mdi-plus </v-icon> Ingresar</v-btn
    >
    <br /><br />
    <div v-if="filesList.length != 0">
      <v-overlay :value="loading" absolute>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-data-table
        :headers="[
          { text: 'Archivo', value: 'file_name' },
          { text: 'Acciones', value: 'actions' },
        ]"
        :items="filesList"
        class="elevation-1"
        :loading="loading"
        loading-text="Cargando... Favor de esperar"
      >
        <template v-slot:item.actions="{ item }">
          <v-row align="center">
            <v-btn
              color="info"
              elevation="5"
              outlined
              icon
              :href="item.file"
              target="_blank"
            >
              <v-icon dark> mdi-download </v-icon>
            </v-btn>
            <button-delete-file-register-indicator :fileRegister="item" />
          </v-row>
        </template>
      </v-data-table>
    </div>
    <div v-else>Sin Archivos Adjuntos</div>
  </section>
</template>
<script>
import { eventBus } from "../../main";
import { FileRegisterIndicatorService } from "../../services/models/fileRegisterIndicator";
import ButtonDeleteFileRegisterIndicator from "./ButtonDeleteFileRegisterIndicator.vue";
import InputFiles from "./InputFiles.vue";

export default {
  components: { InputFiles, ButtonDeleteFileRegisterIndicator },
  props: ["register_indicator"],
  data() {
    return {
      loading: false,
      filesList: [],
    };
  },
  methods: {
    mostrarModal() {
      this.dialog = true;
    },
    async loadData() {
      try {
        this.loading = true;
        this.filesList = await FileRegisterIndicatorService.findByRegisterIndicatorId(
          this.register_indicator.id
        );
        this.loading = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async saveFiles() {
      try {
        for (let i = 0; i < this.$refs.inputFiles.filesList.length; i++) {
          this.showCountdown(i, this.$refs.inputFiles.filesList.length);
          let formData = new FormData();
          formData.append("register_indicator_id", this.register_indicator.id);
          formData.append("file", this.$refs.inputFiles.filesList[i]);
          formData.append(
            "user_id",
            JSON.parse(localStorage.getItem("user")).id
          );
          await FileRegisterIndicatorService.create(formData, {
            "Content-Type": "multipart/form-data",
          });
        }
        this.hideCountdown();
        eventBus.$emit("clearFilesRegisterIndicatorList");
        this.loadData();
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.loadData();
    eventBus.$on("loadTableFilesRegisterIndicators", () => {
      this.loadData();
    });
  },
};
</script>
<style scoped>
.truncate {
  width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>