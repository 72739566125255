<template>
  <v-list-item-group v-model="selectedItem" dark>
    <v-list-item v-for="(item, i) in items" :key="i" :to="item.to">
      <v-list-item-icon>
        <v-icon v-text="item.icon"></v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="item.text"></v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list-item-group>
</template>
<script>
export default {
  data() {
    return {
      selectedItem: 1,
      items: [
        { text: "Inicio", icon: "mdi-home", to: { name: "manegementControlHome" } },
        {
          text: "Registro",
          icon: "mdi-view-list",
          to: { name: "registerManegementControl" },
        },
        {
          text: "Reportes",
          icon: "mdi-file-chart",
          to: { name: "reportsManagementControl" },
        },
        {
          text: "Mantenedor de Usuarios",
          icon: "mdi-account-multiple",
          to: { name: "usersManagementControl" },
        },
        {
          text: "Mantenedor de Instrumentos de Control de Gestión",
          icon: "mdi-file-chart",
          to: { name: "typesIndicatorsManagementControl" },
        },
        {
          text: "Mantenedor de Clasificación de Indicadores",
          icon: "mdi-view-list",
          to: { name: "classificationIndicatorsManagementControl" },
        },
        {
          text: "Mantenedor de Indicadores",
          icon: "mdi-chart-arc",
          to: { name: "IndicatorManagementControl" },
        },
      ],
    };
  },
};
</script>