import Vue from "vue"
import VueRouter from 'vue-router'
import { routes } from './routes';

Vue.use(VueRouter)

const router = new VueRouter({
  routes: routes,
  mode: "history",
});

// router.beforeEach((to, from, next) => {
//   let isAuthenticated = window.localStorage.getItem("token") || undefined;
//   if (to.name !== "Login" && !isAuthenticated) {
//     next({ name: "Login" });
//   } else {
//     console.log('next')
//     next();
//   }
// });

export default router;