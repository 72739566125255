import {
    BaseService
} from './../base'
export class ClassificationIndicatorService extends BaseService {
    static get entity() {
        return 'classification_indicator'
    }
    static get name() {
        return 'classification_indicator'
    }
    static async findTypeId(id) {
        return this.submit("get", `${this.profile}/${this.entity}/find_by_type_indicator_id/${id}`, this.name);
    }
}