import Vue from 'vue';
import Vuetify from 'vuetify/lib';

// const vuetify = new Vuetify({
    
// })
Vue.use(Vuetify);
export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#0FCBBF',
            secondary: '#334B48',
            // accent: '#8c9eff',
            // error: '#b71c1c',
          },
        },
    },
});
