

        <template>
  <div style="width: 100%">
      <v-row>
          <v-col cols="4">
                <v-select
                :items="listType"
                item-text="name"
                item-value="id"
                label="Tipo de Indicador"
                v-model="type_id"
                @change="chargeListClasifications()"
                ></v-select>
          </v-col>
          <v-col cols="4">
                <v-select
                :items="listClasification"
                v-model="classification_id"
                item-text="name"
                item-value="id"
                label="Objetivos"
                @change="chargeIndicators()"
                ></v-select>
          </v-col>
          <v-col cols="4">
                <v-select
                :items="listIndicators"
                v-model="indicator_id"
                item-text="name"
                item-value="id"
                label="Indicadores"
                @change="changeIndicator()"
                ></v-select>
          </v-col>
      </v-row>
                  <v-spacer></v-spacer>

  </div>
</template>

<script>
//import CreateUser from "../../../components/manteiners/users/CreateUser";
//import EditUser from "../../../components/manteiners/users/EditUser";
//import DeleteUser from "../../../components/manteiners/users/DeleteUser";
import { TypeIndicatorService } from '../../../services/models/typeIndicator';
import { ClassificationIndicatorService } from '../../../services/models/classificationIndicator'
import {eventBus} from '../../../main';
import { IndicatorService } from '../../../services/models/indicator';

export default {
  components: {
   
  },
  data() {
    return {
      listType: [],
      listClasification:[],
      listIndicators:[],

      search: "",
      loading: false,
      type_id:"",
      classification_id:null,
      indicator_id:0
    };
  },
  methods: {
    async chargeListType() {
      this.loading = true;
      this.listType = await TypeIndicatorService.getAll();
      this.loading = false;
    },
    async chargeListClasifications(){
      this.loading = true;
      this.listClasification = await ClassificationIndicatorService.findTypeId(this.type_id);      
      this.loading = false;
    },
    async chargeIndicators(){
    this.loading=true;    
    eventBus.$emit('loadClassification', this.classification_id);
    this.listIndicators=await IndicatorService.findClassificationId(this.classification_id);
    this.listIndicators.push(
      {name:'Ninguno',id:0}
    );
    this.loading=false;
    },
    async changeIndicator(){
    this.loading=true;
    if(this.indicator_id!==0){
      eventBus.$emit('loadFindSubIndicator', this.indicator_id);
    }else{
      eventBus.$emit('loadClassification', this.classification_id);
    }
    this.loading=false;
    }
  },
  mounted() {
    this.chargeListType();
  },
};
</script>