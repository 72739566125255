import EmptyRouterView from './../views/administrator/EmptyRouterView.vue';
import Administrator from './../views/administrator/Administrator.vue';
import Users from './../views/administrator/manteiners/user/Users.vue';
// import TypesIndicators from './../views/administrator/manteiners/typesIndicators/TypeIndicator.vue';
// import ClassificationIndicator from './../views/administrator/manteiners/classificationsIndicators/ClassificationIndicator.vue';
// import Indicator from './../views/administrator/manteiners/indicators/Indicator.vue';
import Reports from './../views/administrator/ReportsView.vue';

export const routes = [
  { path: '/administrador',
    component: EmptyRouterView,
    children: [
      {
        path: 'inicio',
        name: 'administratorHome', 
        component: Administrator,
      },      
      {
        path: 'mantenedor-usuarios',
        name: 'usersAdministrator', 
        component: Users,
      },    
      // {
      //   path: 'mantenedor-Tipo_indicadores',
      //   name: 'typesIndicatorsAdministrator', 
      //   component: TypesIndicators,
      // },    
      // {
      //   path: 'mantenedor-clasificacion_indicadores',
      //   name: 'classificationIndicatorsAdministrator', 
      //   component: ClassificationIndicator,
      // },    
      // {
      //   path: 'mantenedor-Indicadores',
      //   name: 'IndicatorAdministrator', 
      //   component: Indicator,
      // },
      {
        path: 'reportes',
        name: 'reportsAdministrator', 
        component: Reports,
      }  
    ]
  },
]