<template>
  <v-list-item-group v-model="selectedItem" dark>
    <v-list-item v-for="(item, i) in items" :key="i" :to="item.to">
      <v-list-item-icon>
        <v-icon v-text="item.icon"></v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="item.text"></v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list-item-group>
</template>
<script>
export default {
  data() {
    return {
      selectedItem: 1,
      items: [
        { text: "Inicio", icon: "mdi-home", to: { name: "administratorHome" } },
        {
          text: "Reportes",
          icon: "mdi-file-chart",
          to: { name: "reportsAdministrator" },
        },
        {
          text: "Mantenedor de Usuarios",
          icon: "mdi-account-multiple",
          to: { name: "usersAdministrator" },
        },
        // {
        //   text: "Mantenedor de Tipo de Indicadores",
        //   icon: "mdi-file-chart",
        //   to: { name: "typesIndicatorsAdministrator" },
        // },
        // {
        //   text: "Mantenedor de Clasificación de Indicadores",
        //   icon: "mdi-view-list",
        //   to: { name: "classificationIndicatorsAdministrator" },
        // },
        // {
        //   text: "Mantenedor de Indicadores",
        //   icon: "mdi-chart-arc",
        //   to: { name: "IndicatorAdministrator" },
        // },
      ],
    };
  },
};
</script>