import Login from "./../views/Login.vue";

import {
    routes as administratorRoutes
} from "./administrator";
import {
    routes as administrativeRoutes
} from "./administrative";
import {
    routes as manegement_control
} from "./manegement_control";

export const routes = [{
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/",
        name: "Login",
        component: Login,
    },
    ...administratorRoutes,
    ...administrativeRoutes,
    ...manegement_control,
    {
        path: '*',
        redirect: '/'
    }
];