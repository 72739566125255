<template>
  <v-data-table
    :headers="headers"
    :items="listRegister"
    item-key="rut"
    class="elevation-1"
    :loading="loading"
    loading-text="Cargando... Favor de esperar"
    :search="search"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <create />
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-row align="center">
        <edit :register_indicator="item" />
        <list-notes :register_indicator_id="item.id"></list-notes>
        <modal-modifications-register :register_indicator="item" />
      </v-row>
    </template>
  </v-data-table>
</template>
<script>
import ListNotes from "../../management_control/notes/ListNotes";
import Edit from "./Edit.vue";
import ModalModificationsRegister from './ModalModificationsRegister.vue';
import { RegisterIndicatorService } from '../../../services/models/register_indicator';

export default {
  props: ["indicator_id"],
  components: {
    ListNotes,    
    Edit,
    ModalModificationsRegister,    
    
  },
  data() {
    return {
      listRegister: [],
      search: "",
      loading: false,

      headers: [
        { text: "Responsable", value: "user.name", sortable: true },
        { text: "Mes", value: "month", align: "start", sortable: true },
        { text: "Año", value: "year", align: "start", sortable: true },

        {
          text: "Numerador",
          value: "numerator",
          align: "start",
          sortable: true,
        },
        { text: "Denominador", value: "denominator", sortable: true },
        { text: "Resultado", value: "result", sortable: true },
        { text: "Acciones", value: "actions", sortable: true },
      ],
    };
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.listRegister = await RegisterIndicatorService.findByIndicatorId(
          this.indicator_id
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>