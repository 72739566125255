import {
    BaseService
} from '../base'
export class RegisterIndicatorService extends BaseService {
    static get entity() {
        return 'register_indicator'
    }
    static get name() {
        return 'register_indicator'
    }
    static async getByIndicatorId(id) {
        return this.submit("get", `${this.profile}/${this.entity}/find_by_indicator_id/${id}`, this.name);
    }
    static async findByIndicatorId(id) {
        return this.submit("get", `${this.profile}/${this.entity}/find_by_indicator_id/${id}`, this.name);
    }
}