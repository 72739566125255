var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.listSubIndicators,"item-key":"id","loading":_vm.loading,"loading-text":"Cargando... Favor de esperar","no-data-text":"Sin Sub-indicadores"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Sub indicadores")])],1)]},proxy:true},(!_vm.isAdministrative)?{key:"item.create",fn:function(ref){
var item = ref.item;
return [_c('create-register',{attrs:{"indicator_id":item.id}})]}}:null,{key:"item.viewList",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
        name: "viewRegister",
        params: { id: item.id },
      }}},[_c('v-btn',{attrs:{"x-small":"","fab":"","color":"primary","dark":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-eye ")])],1)],1)]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-row',{attrs:{"align":"center"}},[_c('delete',{attrs:{"indicator":item}})],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }