import { BaseService } from './../base'
export class RegisterIndicatorHistoryService extends BaseService {
    static get entity () {
        return 'register_indicator_history'
    }
    static get name () {
        return 'register_indicator_history'
    }
    static async findByRegisterIndicatorId(id) {
        return this.submit("get", `${this.profile}/${this.entity}/find_by_register_indicator_id/${id}`, this.name);
    }
}