<template>
  <v-dialog v-model="dialog" max-width="600px" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        fab
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
        @click="mostrarModal"
      >
        <v-icon dark> mdi-plus </v-icon>
      </v-btn>
    </template>
          <form @submit.prevent="saveData()" ref="form">
            <v-card>
               <v-toolbar flat color="primary" dark>
        <v-toolbar-title>Crear tipo de indicador</v-toolbar-title>
      </v-toolbar>
                      
                      <v-card-text style="height: 100%">
                        <v-overlay :value="loading" absolute>
                          <v-progress-circular indeterminate size="64"></v-progress-circular>
                        </v-overlay>
                        <v-card-text>
                              <v-text-field
                                outlined
                                dense
                                v-model="type_indicator.name"
                                prepend-inner-icon="mdi-list-status"
                                label="Tipo de Indicador"
                                :rules="fieldRules"
                                required
                              />         

                        </v-card-text>
                      </v-card-text>
                      <v-card-actions flat>
                        <v-btn @click="dialog = false">Cerrar</v-btn>
                        <v-btn color="primary" type="submit"><v-icon left small> mdi-content-save </v-icon> Guardar</v-btn>
                      </v-card-actions>
            </v-card>
          </form>
  </v-dialog>
</template>
<script>
import { eventBus } from '../../../main';
import { TypeIndicatorService } from '../../../services/models/typeIndicator';

export default {
  data() {
    return {      
      dialog: false,
      loading: false,
      fieldRules: [(v) => !!v || "Este campo es obligatorio"],
      type_indicator:{
        name:null
      }
    };
  },
  methods: {
    async mostrarModal() {
      this.dialog = true;      
    },
    async saveData(){      
      try {
        this.loading = true;
        await TypeIndicatorService.create(this.type_indicator);
        eventBus.$emit('loadTypeIndicator');        
         this.showAlert("showNotification", {
          text: "Se creo el tipo de indicador",
          color: "success",
        });   
        this.dialog=false;
        this.$refs.form.reset()
      } catch (error) {
        console.log(error);        
        this.showAlert("showNotification", {
          text: "error al crear tipo de indicador",
          color: "error",
        });  
      } finally{                     
        this.loading=false;
      }
    }
  },
  watch:{
    dialog(){
      if(!this.dialog){
        this.$refs.form.reset()
      }
    }
  }
};
</script>