<template>
  <div style="width: 100%">
    <v-container fluid>
      <v-row align="center">
        <v-col class="d-flex" cols="12" sm="2">
          <v-select
            :items="items"
            item-text="name"
            item-value="value"
            v-model="sortDesc"
            dense
            outlined
            label="Orden"
          ></v-select>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :headers="headers"
      :items="listClassificationsIndicators"
      item-key="rut"
      class="elevation-1"
      :loading="loading"
      loading-text="Cargando... Favor de esperar"
      :search="search"
      :sort-by.sync="sortBy"
      :sort-desc="sortDesc"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Clasificación de Indicadores</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <create />
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-row align="center">
          <edit :classificationIndicationData="item" />
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Create from "./Create.vue";
import { ClassificationIndicatorService } from "../../../services/models/classificationIndicator";
import { eventBus } from "../../../main";
import Edit from "./Edit.vue";

export default {
  components: {
    Create,
    Edit,
  },
  data() {
    return {
      search: "",
      items: [
        {
          name: "Descendiente",
          value: true,
        },
        {
          name: "Ascendiente",
          value: false,
        },
      ],
      listClassificationsIndicators: [],
      loading: false,
      sortBy: "name",
      sortDesc: false,
      headers: [
        { text: "Nombre", value: "name", align: "start", sortable: true },
        { text: "Acciones", value: "actions", sortable: true },
      ],
    };
  },
  methods: {
    async chargelistClassificationsIndicators() {
      this.loading = true;
      this.listClassificationsIndicators = await ClassificationIndicatorService.getAll();
      this.loading = false;
    },
  },
  mounted() {
    this.chargelistClassificationsIndicators();
    eventBus.$on("loadClassificationsIndicators", () => {
      this.chargelistClassificationsIndicators();
    });
  },
};
</script>